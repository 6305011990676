import { types as MutationTypes } from './mutations'
import { listChannel } from '@/api/business'

export const GETCHANNEL = async (
  { commit },
  params,
) => {
  try {
    commit(MutationTypes.SET_CHANNEL_STORE, {
      loading: true,
    })
    
    const response = await listChannel(params)

    const { data } = response?.data || []
    const channels = data?.length
      ? data.map(channel => ({
          ...channel,
          title: channel?.title || channel?.sales_channel?.name,
          sales_channel: {
            ...channel.sales_channel,
            code: channel.sales_channel?.code === 'tiktok_tokopedia'
              ? 'tiktok'
              : channel.sales_channel.code,
          },
        }))
      : []

    commit(MutationTypes.SET_CHANNEL_STORE, {
      channels,
    })

    return Promise.resolve({ ...response.data, data: channels })
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_CHANNEL_STORE, {
      loading: false,
    })
  }
}

export const GETSALESCHANNEL = async ({ commit }, params) => {
  let data = ''
  try {
    commit(MutationTypes.SET_CHANNEL_STORE, {
      loading: true,
    })

    const response = await listChannel({ ...params, type: 'sales' })
    data = response.data
    return Promise.resolve(response.data)
  } catch (error) {
    return Promise.reject(error)
  } finally {
    commit(MutationTypes.SET_CHANNEL_STORE, {
      saleschannel: data.data,
      loading: false,
    })
  }
}