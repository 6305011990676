import { types as MutationTypes } from './mutations'
import debounce from 'lodash/debounce'
import { i18n } from '@/i18n'
import { addProductChannel } from '@/api/product'
import {
  getChannelWarehouse,
  uploadChannelImage,
  addChannelProduct,
  getChannelDraft,
  deleteChannelDraft,
  channelEventProcess,
  channelImageDraft,
} from '@/api/channels/index'
import { shopeeImageAttribute } from '@/api/channels/shopee'

export const ADD_PRODUCT_CHANNEL = debounce(async ({ commit, dispatch, state }) => {
  const channels = ['shopee', 'shopee_id', 'tiktok', 'tiktok_id', 'tokopedia', 'tokopedia_id']
  if(state.model?.channels?.length && 
    !state.model.channels[0].deleted && 
    channels.includes(state.model.channels[0].code) && 
    !state.existingChannels.includes(state.model.channels[0].id)
  ) {
    const channel = state.model.channels[0]
    commit(MutationTypes.SET_ADD_CHANNEL, true)
    commit(MutationTypes.SET_CHANNEL, channel.code)

    let warehouses = []
    let bodyVal = {
      business_id: state.model.business_id,
      channel_code: channel.code,
      channel_id: channel.id,
      user_id: state.model.user_id,
    }

    if(channel.code.includes('tokopedia')) {
      dispatch('GET_TOKOPEDIA_DRAFT', bodyVal)
    } else {
      if(channel.code.includes('tiktok')) {
        await getChannelWarehouse({
          business_id: state.model.business_id,
          channel_code: channel.code,
          channel_id: channel.id,
          connected: false,
        })
        .then(({ data: { data: response } }) => {
          warehouses = response.warehouses.filter(warehouse => warehouse.is_default)
        })
      }

      dispatch('UPLOAD_CHANNEL_IMAGE', {
        warehouses,
        data: bodyVal,
      })
    }
  }
}, 500)

export const UPLOAD_CHANNEL_IMAGE = async({ dispatch, state }, payload) => {
  let requests = []
  state.model.images.forEach(image => {
    requests.push(uploadChannelImage({
      ...payload.data,
      product_id: payload.data.channel_code.includes('shopee')
        ? ''
        : payload.data.channel_code.includes('tokopedia')
        ? payload.data.product_id
        : 1,
      file: image.file,
      image_type: 1,
    }))
  })

  await Promise.all(requests)
    .then(response => {
      if(!payload.data.channel_code.includes('tiktok')) {
        dispatch('CHANNEL_IMAGE_PROCESS', {
          response,
          data: payload.data,
        })
      } else {
        const images = response.map((image, index) => ({
          ...image.data.data,
          ref_id: state.model.images[index].ref_id,
        }))
        dispatch('SAVE_PRODUCT_CHANNEL', {
          images,
          warehouses: payload.warehouses,
          data: payload.data,
        })
      }
    })
    .catch(async () => {
      dispatch('PRODUCT_STATUS', {
        id: payload.data.channel_id,
        code: payload.data.channel_code,
        status: 'failed',
      })

      if(payload.data.channel_code.includes('tokopedia')) {
        await deleteChannelDraft(payload.data)
      }
    })
}

export const GET_TOKOPEDIA_DRAFT = async ({ dispatch }, payload) => {
  await getChannelDraft(payload)
    .then(response => {
      dispatch('UPLOAD_CHANNEL_IMAGE', {
        warehouses: [],
        data: {
          ...payload,
          product_id: response.data.data.product_id,
        },
      })
    })
    .catch(async (err) => {
      if(err.response.data.message.includes('exists')) {
        const id = err.response.data.message.replace('Draft ', '').replace(' exists', '')
        await deleteChannelDraft({
          ...payload,
          product_id: id,
        })
        .then(() => dispatch('GET_TOKOPEDIA_DRAFT', payload))
      }
    })
}

export const CHANNEL_IMAGE_PROCESS = debounce(async ({ dispatch }, payload) => {
  let requests = []
  let request_ids = []
  payload.response.forEach(res => {
    const request_id = payload.data.channel_code.includes('shopee')
      ? res.data.request_id
      : res.data.data.request_id
    request_ids.push(request_id)
    requests.push(channelEventProcess({
      ...payload.data,
      user_id: undefined,
      request_id,
    }))
  })

  await Promise.all(requests)
    .then(response => {
      const isFailed = payload.data.channel_code.includes('shopee')
        ? response.every(res => res.data.status === 'FAILED')
        : response.every(res => res.data.data.status === 'FAILED')
      const isOnProgress = payload.data.channel_code.includes('shopee')
        ? response.some(res => res.data.status === 'ON_PROGRESS')
        : response.some(res => res.data.data.status === 'ON_PROGRESS')
      const isFinished = payload.data.channel_code.includes('shopee')
        ? response.every(res => res.data.status === 'FINISHED')
        : response.every(res => res.data.data.status === 'FINISHED')
      if(isOnProgress) {
        dispatch('CHANNEL_IMAGE_PROCESS', payload)
      } else if(isFailed) {
        dispatch('PRODUCT_STATUS', {
          id: payload.data.channel_id,
          code: payload.data.channel_code,
          status: 'failed',
        })
      } else if(isFinished) {
        if(payload.data.channel_code.includes('shopee')) {
          dispatch('SHOPEE_DRAFT_IMAGE', {
            request_ids,
            data: payload.data,
          })
        } else if (payload.data.channel_code.includes('tokopedia_id')) {
          dispatch('GET_DRAFT_IMAGE', {
            data: payload.data,
          })
        } else {
          dispatch('SAVE_PRODUCT_CHANNEL', {
            images: request_ids,
            data: payload.data,
          })
        }
      }
    })
    .catch(() => {
      dispatch('PRODUCT_STATUS', {
        id: payload.data.channel_id,
        code: payload.data.channel_code,
        status: 'failed',
      })
    })
}, 2000)

export const SHOPEE_DRAFT_IMAGE = async ({ dispatch }, payload) => {
  let requests = []
  payload.request_ids.forEach(request_id => {
    requests.push(shopeeImageAttribute({
      ...payload.data,
      user_id: undefined,
      params: {
        request_id,
        user_id: payload.data.user_id,
      },
    }))
  })

  await Promise.all(requests)
    .then(response => {
      const images = response.map(res => res.data[0].channel_image_id)
      dispatch('SAVE_PRODUCT_CHANNEL', {
        images,
        data: payload.data,
      })
    })
    .catch(() => {
      dispatch('PRODUCT_STATUS', {
        id: payload.data.channel_id,
        code: payload.data.channel_code,
        status: 'failed',
      })
    })
}
export const GET_DRAFT_IMAGE = async ({ dispatch }, payload) => {
    channelImageDraft({
      ...payload.data,
    })
    .then(({ data: response }) => {
      const images = response.images?.map(res => ({
        id: res.image_id,
        img_url: res.url_list[0],
      }))
      dispatch('SAVE_PRODUCT_CHANNEL', {
        images,
        data: payload.data,
      })
    })
    .catch(() => {
      dispatch('PRODUCT_STATUS', {
        id: payload.data.channel_id,
        code: payload.data.channel_code,
        status: 'failed',
      })
    })
}

export const SAVE_PRODUCT_CHANNEL = async ({ dispatch, state }, payload) => {
  let data
  if(payload.data.channel_code.includes('tiktok')) {
    const attribute = state.model.attributes.find(attr => {
      return attr.id == payload.data.channel_id && attr.code == payload.data.channel_code
    })
    const weight = ['g', 'gr'].includes(state.model.units?.[0]?.package?.weight_unit?.toLowerCase())
      ? state.model.units?.[0]?.package?.weight / 1000
      : state.model.units?.[0]?.package?.weight
    const images = payload.images.map(image => ({
      id: image.img_id,
      img_url: image.img_url,
    }))

    let product_attributes = []
    attribute.attribute.forEach(attr => {
      product_attributes.push({
        attribute_id: attr.attribute_id,
        attribute_values: attr.attribute_value_list.map(val => ({
          value_id: val.value_id,
          value_name: val.original_value_name,
        })),
      })
    })

    let skus = []
    state.model.catalogs.items.map(catalog => {
      let i = 0
      let sales_attributes = []
      for(const variant in (catalog.option || {})) {
        let findImage
        if(i == 0) findImage = payload.images.find(image => image.ref_id === catalog.images[0].refId)
        sales_attributes.push({
          attribute_name: variant,
          custom_value: catalog.option[variant],
          sku_img: i == 0
            ? { id: findImage.img_id }
            : undefined,
        })
        i++
      }

      skus.push({
        original_price: catalog.price.value,
        seller_sku: catalog.sku,
        sales_attributes: sales_attributes.length
          ? sales_attributes
          : undefined,
        stock_infos: [{
          warehouse_id: payload.warehouses[0].warehouse_id,
          available_stock: 0,
        }],
      })
    })
    
    data = {
      user_id: payload.data.user_id,
      request_body: {
        product_name: state.model.detail.title,
        description: state.model.detail.long_description.replace( /(<([^>]+)>)/ig, ''),
        category_id: attribute.category[3]
          || attribute.category[2]
          || attribute.category[1]
          || attribute.category[0],
        images,
        package_height: state.model.units?.[0]?.package?.height || 0,
        package_length: state.model.units?.[0]?.package?.length || 0,
        package_weight: weight || 0,
        package_width: state.model.units?.[0]?.package?.width || 0,
        size_chart: {
          img_id: images[0].id,
        },
        skus,
        product_attributes: product_attributes.length
          ? product_attributes
          : undefined,
        is_cod_open: false,
      },
    }
  } else if(payload.data.channel_code.includes('shopee')) {
    let tier_variation = []
    let model = []

    state.model.catalogs.items.forEach(catalog => {
      let i = 0
      let tier_index = []
      const imgIndex = catalog.images.length ?
        state.model.images.findIndex(img => img.ref_id === catalog.images[0].refId)
        : -1
      for(const attribute in (catalog.option || {})) {
        if(i < 2) {
          const attrIndex = state.model.catalogs.options.findIndex(attr => attr === attribute)
          const varIndex = state.model.catalogs.attributes[attribute].findIndex(option => option === catalog.option[attribute])
          tier_index.push(varIndex)
          tier_variation[attrIndex] = {
            name: attribute,
            option_list: tier_variation[attrIndex]?.option_list || [],
          }
          tier_variation[attrIndex].option_list[varIndex] = {
            ...tier_variation[attrIndex].option_list[varIndex],
            option: catalog.option[attribute],
            image: attrIndex == 0 && imgIndex > -1
              ? { image_id: payload.images[imgIndex] }
              : undefined,
          }
        }
        
        i++
      }

      model.push({
        tier_index,
        normal_stock: 1,
        original_price: catalog.price.value,
        model_sku: catalog.sku?.toUpperCase(),
        seller_stock: [{
          stock: 0,
        }],
      })
    })
    
    const attribute = state.model.attributes.find(attr => {
      return attr.id == payload.data.channel_id && attr.code == payload.data.channel_code
    })
    const weight = ['g', 'gr'].includes(state.model.units?.[0]?.package?.weight_unit?.toLowerCase())
      ? state.model.units?.[0]?.package?.weight / 1000
      : state.model.units?.[0]?.package?.weight

    data = {
      detail: {
        title: state.model.detail.title,
        description: state.model.detail.long_description.replace( /(<([^>]+)>)/ig, ''),
        item_status: 'NORMAL',
        original_price: state.model.catalogs.items[0].price.value,
        weight,
        image: {
          image_id_list: payload.images,
        },
        sku: state.model.catalogs.items[0].sku?.toUpperCase(),
        condition: state.model.detail.condition,
        brand: {
          brand_id: 0,
          original_brand_name: state.model.brand_id,
        },
        dimension: {
          package_height: state.model.units?.[0]?.package?.height,
          package_length: state.model.units?.[0]?.package?.length,
          package_width: state.model.units?.[0]?.package?.width,
        },
        pre_order: {
          is_pre_order: false,
          days_to_ship: 3,
        },
        logistic_info: [{
          logistic_id: 8003,
          enabled: true,
          is_free: false,
        }],
        category_id: attribute.category[3]
          || attribute.category[2]
          || attribute.category[1]
          || attribute.category[0],
        seller_stock: [{
          stock: 0,
        }],
        attribute_lists: attribute.attribute,
        catalog: {
          tier_variation,
          model,
        },
      },
    }
  } else if(payload.data.channel_code.includes('tokopedia')) {
    const attribute = state.model.attributes.find(attr => {
      return attr.id == payload.data.channel_id && attr.code == payload.data.channel_code
    })
    const weight_unit = ['g', 'gr'].includes(state.model.units?.[0]?.package?.weight_unit?.toLowerCase())
      ? 'GR'
      : 'KG'
    const images = payload.images.map(image => ({
      id: image.id,
      image_url: image.img_url,
    }))

    let catalogs = []
    state.model.catalogs.items?.forEach(item => {
      let attributes = []
      for(const prop in item.option) {
        attributes.push({
          attribute_name: prop,
          attribute_value: item.option[prop],
        })
      }

      catalogs.push({
        sku: item.sku,
        status: 'LIMITED',
        price: {
          currency: item.price.currency,
          value: item.price.value,
        },
        attributes,
        warehouse: {
          stock: 1,
        },
      })
    })

    let annotations = []
    attribute.attribute.forEach(attr => {
      attr.attribute_value_list.forEach(val => {
        annotations.push(val.value_id)
      })
    })
    
    data = {
      detail: {
        title: state.model.detail.title,
        long_description: state.model.detail.long_description.replace( /(<([^>]+)>)/ig, ''),
        category: {
          id: attribute.category[3]
            || attribute.category[2]
            || attribute.category[1]
            || attribute.category[0],
        },
        is_new: state.model.detail.condition === 'NEW' ? true : false,
        is_must_insurance: false,
        is_free_return: false,
        min_order: 1,
        images,
        annotations,
      },
      catalogs,
      product_unit: {
          width: state.model.units?.[0]?.package?.width,
          length: state.model.units?.[0]?.package?.length,
          height: state.model.units?.[0]?.package?.height,
          weight: state.model.units?.[0]?.package?.weight,
          weight_unit,
      },
    }
  }
  
  const bodyVal = {
    ...payload.data,
    product_id: payload.data.channel_code.includes('tokopedia')
    ? payload.data.product_id
    : '',
    data,
  }

  await addChannelProduct(bodyVal)
    .then(response => dispatch('PRODUCT_CHANNEL_PROCESS', {
      request_id: response.data.data.request_id,
      data: payload.data,
    }))
    .catch(async () => {
      dispatch('PRODUCT_STATUS', {
        id: payload.data.channel_id,
        code: payload.data.channel_code,
        status: 'failed',
      })

      if(payload.data.channel_code.includes('tokopedia')) {
        await deleteChannelDraft(payload.data)
      }
    })
}

export const PRODUCT_CHANNEL_PROCESS = debounce(async ({ commit, dispatch, state }, payload) => {
  await channelEventProcess({
    ...payload.data,
    user_id: undefined,
    request_id: payload.request_id,
  })
    .then(async ({ data }) => {
      const status = payload.data.channel_code.includes('tokopedia')
        ? data.data.status
        : data.status
      if (status === "FAILED") {
        let description = ''
        let messages = []
        if(payload.data.channel_code.includes('tokopedia') && data?.data?.message?.length) {
          data.data.message.forEach(message => {
            if(message?.error?.length) message.error.forEach(msg => messages.push(msg))
          })
          description = messages.join(', ')
        }
        
        dispatch('PRODUCT_STATUS', {
          id: payload.data.channel_id,
          code: payload.data.channel_code,
          status: 'failed',
          message: description,
        })

        if(payload.data.channel_code.includes('tokopedia')) {
          await deleteChannelDraft(payload.data)
        }
      } else if (status === "ON_PROGRESS") {
        dispatch('PRODUCT_CHANNEL_PROCESS', payload)
      } else if (status === "FINISHED") {
        const channel_ids = [payload.data.channel_id].concat(state.channels)
        commit(MutationTypes.SET_CHANNELS, channel_ids)
        dispatch('SAVE_CHANNEL', payload.data)
      }
    })
    .catch(async () => {
      dispatch('PRODUCT_STATUS', {
        id: payload.data.channel_id,
        code: payload.data.channel_code,
        status: 'failed',
      })

      if(payload.data.channel_code.includes('tokopedia')) {
        await deleteChannelDraft(payload.data)
      }
    })
}, 2000)

export const SAVE_CHANNEL = async ({ dispatch, state }, payload) => {
  const mapWhs = state.model.inventories.map(warehouse => warehouse.id)
  const diffWarehouses = state.model.relations.filter(warehouse => {
    return !mapWhs.includes(warehouse)
  })
  
  await addProductChannel({
    business_id: payload.business_id,
    product_id: state.model.id,
    data: {
      business_id: payload.business_id,
      product_id: state.model.id,
      catalog_ids: state.model.catalogs.items.map(catalog => catalog.id),
      status: state.model.status,
      base_unit: state.model.units[0]?.unit,
      channels: state.existingChannels.concat(state.channels),
      channels_delete: [],
      warehouse_ids: mapWhs,
      warehouse_ids_delete: diffWarehouses,
    },
  })
  .then(() => {
    dispatch('PRODUCT_STATUS', {
      id: payload.channel_id,
      code: payload.channel_code,
      status: 'success',
    })
  })
  .catch(async () => {
    dispatch('PRODUCT_STATUS', {
      id: payload.channel_id,
      code: payload.channel_code,
      status: 'failed',
    })

    if(payload.channel_code.includes('tokopedia')) {
      await deleteChannelDraft(payload)
    }
  })
}

export const PRODUCT_STATUS = ({ commit, dispatch, state }, payload) => {
  const channel_ids = [payload.id].concat(state[payload.status])
  const channels = state.model.channels.filter(channel => !channel.code.includes(payload.code))
  const msg = payload?.message ? `- ${payload.message}` : ''
  commit(MutationTypes[`SET_${payload.status.toUpperCase()}`], channel_ids)
  commit(MutationTypes.SET_STATUS, {
    code: payload.status.toUpperCase(),
    message: `${i18n.t(`channel.product.${payload.code}`)} ${payload.status === 'success' ? 'Berhasil' : 'Gagal'} di simpan ${msg}`,
  })
  commit(MutationTypes.SET_MODEL, {
    ...state.model,
    channels: channels && channels.length
      ? channels
      : [],
  })
  if(channels.length) dispatch('ADD_PRODUCT_CHANNEL')
}
